import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

import store from '../store'
import Axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresUser: true
    },
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      requiresAdmin: true
    },
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Manage company',
        component: () => import('../components/adminManageCompany.vue'),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: '/admin/errors',
        name: 'Errors',
        component: () => import('../components/adminErrorsView.vue'),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: '/admin/new-portal-user',
        name: 'New Portal User',
        component: () => import('../components/adminNewPortalUser.vue'),
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: '/admin/manage-portal-users',
        name: 'Manage Portal Users',
        component: () => import('../components/adminManagePortalUsers.vue'),
        meta: {
          requiresAdmin: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

Axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('logout')
    router.push('/')
  }
  return Promise.reject(error)
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresUser)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!store.getters.isAdmin || !store.getters.loggedIn) {
      next({
        path: '/dashboard',
      })
    } else {
      next()
    }
  }
  else {
    next()
  }
})

export default router
