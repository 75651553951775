import { createStore } from 'vuex'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

export default createStore({
  state: {
    JWT: sessionStorage.getItem('access_token') || null,
    exp: sessionStorage.getItem('exp') || null,
    user: sessionStorage.getItem('user') || null,
    allErrors: [],
    userCompanies: [],
    allCompanies: [],
    allCustomers: [],
    allResponsibleUsers: [],
    allProjects: [],
    allPortalUsers: [],
    showAllProjects: (sessionStorage.getItem('showAllProjects') === 'true') || false,
    projectsOrderBy: JSON.parse(sessionStorage.getItem('projectsOrderBy')) || { field: 'id', direction: 'asc' }
  },
  mutations: {
    retrievedJWT (state, {
      token,
      exp,
      user
    }) {
      state.JWT = token
      state.exp = exp
      state.user = user
    },
    destroyToken (state) {
      state.JWT = null
    },
    destroyUserData (state) {
      state.decodedToken = null
    },
    destroyRole (state) {
      state.userRole = null
    },
    gotUserCompanies (state, data) {
      state.userCompanies = data
    },
    gotAllCompanies (state, data) {
      state.allCompanies = data
    },
    gotCustomers (state, data) {
      state.allCustomers = data
    },
    gotResponsibleUsers (state, data) {
      state.allResponsibleUsers = data
    },
    gotErrors (state, data) {
      state.allErrors = data
    },
    gotProjects (state, data) {
      state.allProjects = data
    },
    gotPortalUsers (state, data) {
      state.allPortalUsers = data
    },
    toggleShowAllProjects (state) {
      state.showAllProjects = !state.showAllProjects
      sessionStorage.setItem('showAllProjects', state.showAllProjects)
    },
    changeProjectsOrderBy (state, data) {
      state.projectsOrderBy = {
        field: data.field,
        direction: data.direction
      }
      
      sessionStorage.setItem('projectsOrderBy', JSON.stringify(state.projectsOrderBy))
    }
  },
  getters: {
    loggedIn: state => state.JWT !== null,
    isAdmin: () => sessionStorage.getItem('user_role') === '1',
    getAllProjects: state => state.allProjects,
    showAllProjects: state => state.showAllProjects,
    projectsOrderBy: state => state.projectsOrderBy
  },
  actions: {
    async loginUserAndGetJWT ({commit}, credentials) {
      return new Promise((resolve, reject) => {
        const data = { name: credentials.name, password: credentials.password }
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/login`, data)
          .then((response) => {
            const token = response.data
            const decodedToken = jwt_decode(token)
            const userRole = decodedToken.payload.roleId
            const exp = decodedToken.exp
            const user = decodedToken.payload.user
            sessionStorage.setItem('access_token', token)
            sessionStorage.setItem('user_role', userRole)
            sessionStorage.setItem('expires', exp)
            sessionStorage.setItem('user', user)
            commit('retrievedJWT', {
              token,
              exp,
              user
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout (context) {
      sessionStorage.clear()
      context.commit('destroyToken', 'destroyUserData', 'destroyRole')
      return
    },
    addNewCompany (context, newCompanyData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/companies`, newCompanyData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getUserCompanies (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/companies/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotUserCompanies', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAllCompanies (context) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/companies/all-companies/data`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotAllCompanies', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editCompany (context, editedCompanyData) {
      const companyId = editedCompanyData.companyId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/companies/${companyId}`, editedCompanyData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    addNewPortalUser (context, newPortalUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/admin/portal-users/new-user `, newPortalUserData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getPortalUsers (context) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/admin/portal-users`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotPortalUsers', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editPortalUser (context, editedPortalUserData) {
      const portalUserId = editedPortalUserData.portalUserId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/admin/portal-users/${portalUserId}`, editedPortalUserData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    changePortalUserPassword (context, editedPortalUserData) {
      const portalUserId = editedPortalUserData.portalUserId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/admin/portal-users/change-password/${portalUserId}`, editedPortalUserData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deletePortalUser (context, portalUserId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/api/admin/portal-users/${portalUserId}`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNewCustomer (context, newCustomerData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/customers`, newCustomerData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getCustomers (context, companyId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/companies/${companyId}/customers`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotCustomers', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editCustomer (context, editedCustomerData) {
      const customerId = editedCustomerData.customerId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/customers/${customerId}`, editedCustomerData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteCustomer (context, customerId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/api/customers/${customerId}`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNewResponsibleUser (context, newResponsibleUserData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/responsible-users`, newResponsibleUserData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getResponsibleUsers (context, companyId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/companies/${companyId}/responsible-users`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotResponsibleUsers', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editResponsibleUser (context, editedResponsibleUserData) {
      const responsibleUserId = editedResponsibleUserData.responsibleUserId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/responsible-users/${responsibleUserId}`, editedResponsibleUserData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteResponsibleUser (context, responsibleUserId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/api/responsible-users/${responsibleUserId}`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNewProject (context, newProjectData) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_SERVER}/api/projects`, newProjectData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getProjects (context, companyId) {
      return new Promise((resolve, reject) => {
        const id = companyId.companyId

        const query = ['?']

        if (context.state.showAllProjects) {
          query.push('all=true')
        }

        if (context.state.projectsOrderBy) {
          query.push(`orderBy=${context.state.projectsOrderBy.field}&orderDirection=${context.state.projectsOrderBy.direction}`)
        }

        axios.get(`${process.env.VUE_APP_API_SERVER}/api/companies/${id}/projects${query.length > 1 ? query.join('&') : ''}`,{
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotProjects', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editProject (context, editedProjectData) {
      const projectId = editedProjectData.payload.projectId
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_SERVER}/api/projects/${projectId}`, editedProjectData.payload, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteProject (context, projectId) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/api/projects/${projectId}`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getErrors (context) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_SERVER}/api/admin/errors`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            context.commit('gotErrors', response.data)
            resolve
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteError () {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_SERVER}/api/admin/errors`, {
          headers: {
            'Authorization': 'Bearer ' + this.state.JWT
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  modules: {},
})
