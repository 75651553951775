<template>
  <div class="loginForm">
    <div class="login">
      <h3 class="text-center text-white pt-5">
        <img
          src="../assets/pixely-logo-colour.svg"
          width="240"
          height="240"
          class="d-inline-block align-top"
          alt=""
        >
      </h3>
      <div class="container">
        <div class="login-row row justify-content-center align-items-center">
          <div class="col-md-6 login-column">
            <div class="col-md-12 login-box">
              <form
                class="form login-form"
              >
                <h3 class="text-center text-white">Login</h3>
                <div class="form-group">
                  <label for="username" class="text-white">Username:</label><br>
                  <input
                    id="username"
                    v-model="username"
                    type="text"
                    name="username"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="password" class="text-white">Password:</label><br>
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    class="form-control"
                  >
                </div>
                <div class="form-group text-right">
                  <button class="btn btn-secondary btn-md" @click.prevent="handleLogin">Login</button>
                </div>
              </form>
            </div>
            <p v-show="errors" class="mt-5 text-white"> {{ errors }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>Ver.1</div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      errors: ''
    }
  },
  created () {
    if (this.$store.getters.loggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    async handleLogin () {
      await this.$store.dispatch('loginUserAndGetJWT', {
        name: this.username,
        password: this.password,
      })
        .then((response) => {
          if(response){
            this.$router.push('/dashboard')
          }
        })
        .catch (error => {
          this.errors = error.message
        })
    }
  }
}

</script>
